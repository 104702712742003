export const toColors = isDark => ({
  /**
   * Global
   */

  whiteAbsolute: '#ffffff',
  blackAbsolute: '#000000',
  darkAbsolute: '#262940',

  primary: '#B7156B',
  secondary: '#DF1D2C',
  middle: '#C41857',
  white: '#ffffff',
  black: '#000000',
  dark: '#262940',
  night: '#20212A',

  grayGhost: '#fcfcfc',
  grayLight: '#f5f5f5',
  grayAccent: '#eeeeee',
  grayClear: '#dddddd',
  grayBlueBlack: '#40474f',
  grayBlueNight: '#6d8097',
  grayBlueDark: '#8B9AAC',
  grayBlueMedium: '#b3bed5',
  grayBlueNormal: '#d5dbe1',
  grayBlueLight: '#e4e8f0',
  grayBlueGhost: '#F6F9FE',

  transparent: 'transparent',
  background: '#f3f8fa',
  metamask: '#F6851B',

  blue: '#2979FF',
  blueSuite: '#04BEFE',
  blueFaded: '#03A0DC',
  purple: '#7F00FF',
  pink: '#E100FF',
  red: '#F44336',
  green: '#4CAF50',
  green2: '#18cc72',
  green3: '#0CCB7B',
  yellow: '#FFEB3B',
  yellow2: '#FFC83B',
  orange: '#FF9800',

  google: '#E13F2A',

  /**
   * Platform
   */

  platformLight: '#F8F8FE50',
  platform: '#F8F8FE',
  platformMedium: '#F6F6FF',
  content: '#C7C9E3',
  contentLight: '#DDDEEE',
  contentMedium: '#B3B5CC',
  border: '#EFEFFD',
  borderMedium: '#DDDEEE',

  ...(isDark
    ? {
      white: '#1d1f30', // '#262940',
      dark: '#ffffff',

      grayGhost: '#2a2d46',
      grayLight: '#2a2d46',
      grayAccent: '#2a2d46',
      grayClear: '#2a2d46',

      grayBlueBlack: '#ffffff90',
      grayBlueNight: '#ffffff90',
      grayBlueDark: '#ffffff80',
      grayBlueMedium: '#ffffff60',
      grayBlueNormal: '#ffffff50',
      grayBlueLight: '#1d1f3090', // '#26294090',
      grayBlueGhost: '#1d1f3070', // '#26294070',

      platformLight: '#22253940',
      platform: '#222539', // '#2a2d46',
      platformMedium: '#22253970', // '#26294070',

      content: '#ffffff98',
      contentLight: '#ffffff80',
      contentMedium: '#ffffff80',
      border: '#ffffff13',
      borderMedium: '#ffffff40'
    }
    : {})
})

const colors = toColors(false)

export const toGradients = isDark => {
  const colors = toColors(isDark)

  return {
    primary: `linear-gradient(45deg, ${colors.primary}, ${colors.secondary})`,
    primaryBuilder: (angle = 0) =>
      `linear-gradient(${angle}deg, ${colors.primary}, ${colors.secondary})`,
    gold: `linear-gradient(25deg, ${colors.orange} 30%, ${colors.yellow})`,
    green: `linear-gradient(25deg, ${colors.green} 30%, ${colors.green2})`,
    white: `linear-gradient(25deg, ${colors.white} 30%, ${colors.white})`,
    transparentBuilder: (start = '#ffffff', angle = 0) =>
      `linear-gradient(${angle}deg, ${start}, ${start}00)`,
    dark: isDark
      ? 'linear-gradient(45deg, #ffffff05 30%, #00000060)'
      : `linear-gradient(45deg, ${colors.dark} 30%, #3B3F59)`,
    darkReverse: isDark
      ? `linear-gradient(45deg, ${colors.grayBlueLight} 30%, ${colors.grayBlueGhost})`
      : `linear-gradient(45deg, #3B3F59 30%, ${colors.dark})`
  }
}

export const gradients = toGradients(false)

export const fills = {
  primary: `data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg'><linearGradient id='globalGradientPrimary' gradientUnits='userSpaceOnUse'><stop stop-color='%23${colors.primary.slice(
    1
  )}'/><stop offset='1' stop-color='%23${colors.secondary.slice(
    1
  )}'/></linearGradient></svg>#globalGradientPrimary`
}

export default colors
