import _ from 'lodash'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

const WrapperPartial = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${props => props.theme.sizes.edge};
  user-select: none;

  a,
  div {
    display: flex;
    align-items: center;
    p {
      display: inline-flex;
      align-items: center;
    }
  }
  a {
    &:hover,
    &:active {
      * {
        text-decoration: underline;
      }
    }
  }

  p {
    font-size: 11pt;
    font-weight: 600;
    color: ${props => props.theme.colors.dark};
    margin: 0 !important;
  }

  &[data-visible='false'] {
    display: none;
  }
  &[data-label='false'] {
    *[data-component='token-icons'] {
      margin-right: 0;
    }
    *[data-component='token-label'] {
      display: none;
    }
  }

  ${props =>
    !props.isSelfPadded &&
    css`
      padding: 0;
    `}

  ${props => props.theme.medias.medium} {
    padding: 0 calc(${props => props.theme.sizes.edge} * 1 / 2);
    ${props =>
      !props.isSelfPadded &&
      css`
        padding: 0;
      `}
  }
`

const Icons = styled.div`
  margin-right: 12px;
  display: flex;

  img {
    &[src=''],
    &:not([src]) {
      opacity: 0;
    }
  }

  img,
  a {
    height: 26px;
    width: 26px;
    background-color: ${props => props.theme.colors.platform};
    border-radius: 50%;
    flex-shrink: 0;

    margin-left: -8px;
    &:first-child {
      margin-left: 0;
      z-index: 10;
    }

    &:nth-child(2) {
      z-index: 9;
    }
    &:nth-child(3) {
      z-index: 8;
    }
    &:nth-child(4) {
      z-index: 7;
    }
    &:nth-child(5) {
      z-index: 6;
    }
  }
`

const Wrapper = styled(WrapperPartial)`
  &[data-mini='true'] {
    display: flex;
    height: 100%;
    align-items: center;
    & > *[data-component='token-label'] {
      font-weight: 500;
      margin-left: -6px !important;
      flex-wrap: nowrap;
      white-space: nowrap;
    }

    ${Icons} {
      flex-shrink: 0;
      img,
      a {
        height: 20px;
        width: 20px;
        &:not(:first-child) {
          margin-left: -12px;
        }
      }
    }
  }

  &[data-medium='true'] {
    display: flex;
    align-items: center;
    & > *[data-component='token-label'] {
      font-weight: 600;
      margin-left: -4px !important;
      flex-wrap: nowrap;
      white-space: nowrap;
    }

    ${Icons} {
      flex-shrink: 0;
      img,
      a {
        height: 22px;
        width: 22px;
        &:not(:first-child) {
          margin-left: -8px;
        }
      }
    }
  }
`

function TokenMultiDisplay ({
  tokens,
  isSelfPadded,
  isMini,
  isMedium,
  isTarget,
  isLabelIncluded
}) {
  const symbols = useMemo(
    () => _.toArray(tokens).map(token => _.get(token, 'symbol')),
    [tokens]
  )

  const aliases = useMemo(
    () =>
      _.toArray(tokens).map(
        token => _.get(token, 'alias') || _.get(token, 'symbol')
      ),
    [tokens]
  )

  const icons = useMemo(
    () => _.toArray(tokens).map(token => _.get(token, 'icon')),
    [tokens]
  )

  const titles = useMemo(
    () => _.toArray(tokens).map(token => _.get(token, 'title')),
    [tokens]
  )

  const targets = useMemo(
    () =>
      isTarget
        ? _.toArray(tokens).map(
          token =>
              `${_.get(token, 'scanner')}/token/${_.get(token, 'address')}`
        )
        : [],
    [isTarget, tokens]
  )

  return (
    <Wrapper
      data-component='token-container'
      data-label={isLabelIncluded}
      data-visible={!_.isNil(tokens) && !_.isNil(symbols)}
      data-mini={isMini}
      data-medium={isMedium}
      isSelfPadded={isSelfPadded}
    >
      {isTarget ? (
        <>
          <Icons data-component='token-icons'>
            {icons.map((icon, index) => (
              <img key={index} src={icon} alt='' />
            ))}
          </Icons>
          <div data-component='token-label'>
            {symbols.map((symbol, index) => (
              <React.Fragment key={index}>
                {index !== 0 && <p>:</p>}
                <a
                  key={index}
                  target='_blank'
                  rel='noopener noreferrer'
                  href={_.get(targets, index)}
                  title={
                    !_.isNilOrEmptyString(_.get(titles, index))
                      ? _.get(titles, index)
                      : symbol
                  }
                >
                  <p>{_.get(aliases, index)}</p>
                </a>
              </React.Fragment>
            ))}
          </div>
        </>
      ) : (
        <>
          <Icons data-component='token-icons'>
            {icons.map((icon, index) => (
              <img key={index} src={icon} alt='' />
            ))}
          </Icons>
          <p data-component='token-label' title={symbols.join(':')}>
            {aliases.join(':')}
          </p>
        </>
      )}
    </Wrapper>
  )
}

TokenMultiDisplay.propTypes = {
  tokens: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      symbol: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  isLabelIncluded: PropTypes.bool,
  isSelfPadded: PropTypes.bool,
  isMini: PropTypes.bool,
  isMedium: PropTypes.bool,
  isTarget: PropTypes.bool
}

TokenMultiDisplay.defaultProps = {
  tokens: [],
  isSelfPadded: true,
  isLabelIncluded: true,
  isMini: false,
  isMedium: false,
  isTarget: false
}

export default TokenMultiDisplay
