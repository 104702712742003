import IconDashboard from '@material-ui/icons/DashboardOutlined'
import IconCraft from '@material-ui/icons/AddCircleOutlineRounded'
import IconProfile from '@material-ui/icons/AccountCircleOutlined'
import IconOption from '@material-ui/icons/PolicyOutlined'

export const tabs = {
  option: {
    withdraw: 'withdraw',
    exercise: 'exercise',
    mint: 'mint',
    unmint: 'unmint'
  }
}

export const pages = {
  dashboard: {
    route: '/',
    builder: () => '/',
    title: 'Dashboard',
    Icon: IconDashboard,
    depth: 0
  },
  craft: {
    route: '/craft',
    builder: () => '/craft',
    title: 'Craft',
    Icon: IconCraft,
    depth: 0
  },
  profile: {
    route: '/profile',
    builder: () => '/profile',
    title: 'Profile',
    Icon: IconProfile,
    depth: 0
  },
  option: {
    route: '/option/:uuid',
    builder: (uuid = 'search') => `/option/${uuid}`,
    title: 'Option',
    Icon: IconOption,
    depth: 0
  },
  unsupported: {
    route: '/unsupported',
    builder: () => '/unsupported',
    title: 'Unsupported',
    depth: 0
  }
}
