import _ from 'lodash'
import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import validator from 'validator'
import { links } from '@pods-finance/globals'
import Spinner from '../../atoms/Spinner'

export async function sendContactForm ({ email }) {
  const endpoint = new URL(`${links.newsletter.endpoint}`)

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },

    body: JSON.stringify({
      email
    })
  }

  return fetch(endpoint, requestOptions)
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const Status = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  & > p {
    margin: 0 10px 0 0;
    font-size: 10pt;
    font-weight: 400;
    color: ${props => props.theme.colors.white};
    font-style: italic;
  }
`

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 50px;
  border-radius: 2px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.05);
`

const Email = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
`

const Input = styled.input`
  font-size: 16px;
  font-weight: 400;
  color: ${props => props.theme.colors.white};
  height: 100%;
  width: 100%;
  padding: 5px ${props => props.theme.sizes.edge};
  background: transparent;
  -webkit-appearance: none;
  outline: none;
  border: none;

  &::placeholder {
    -webkit-appearance: none;
    color: rgba(255, 255, 255, 0.4);
    font-size: 11pt;
    font-weight: 300;
    opacity: 1;
    ${props => props.theme.extensions.ellipsis};
  }
`

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.05);
  height: 100%;
  padding: 0 calc(${props => props.theme.sizes.edge} * 2.5);
  transition: background-color 150ms;
  cursor: pointer;
  user-select: none;
  & > p {
    font-size: 11pt;
    font-weight: 500;
    color: ${props => props.theme.colors.white};
    text-align: center;
  }
  &:hover,
  &:active {
    background-color: rgba(255, 255, 255, 0.2);
    transition: background-color 150ms;
  }
  &[data-disabled='true'] {
    pointer-events: none;
    opacity: 0.5;
  }
`

function Newsletter ({
  className,
  placeholder,
  action,
  loading,
  success,
  error
}) {
  const [email, setEmail] = useState(null)

  const [info, setInfo] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = useCallback(() => {
    if (_.isNil(email) || !validator.isEmail(email)) {
      setInfo('Please submit a valid email.')
      return
    }

    setInfo(loading)
    setIsLoading(true)
    ;(async () => {
      try {
        const result = await sendContactForm({ email })
        if (result && result.ok) {
          setIsLoading(false)
          setInfo(success)
        } else throw new Error()
      } catch (e) {
        console.error('Newsletter', e)
        setIsLoading(false)
        setInfo(error)
      }
    })()
  }, [email, setIsLoading, setInfo])

  useEffect(() => setInfo(null), [email])

  return (
    <Wrapper className={className}>
      <Box data-component='box'>
        <Email>
          <Input
            placeholder={placeholder}
            value={email || ''}
            onChange={e => setEmail(_.get(e, 'target.value'))}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                onSubmit()
              }
            }}
          />
        </Email>
        <Button
          data-disabled={isLoading || _.toString(info) === success}
          onClick={onSubmit}
          data-component='button'
        >
          <p>{action}</p>
        </Button>
      </Box>
      <Status>
        <p>{info}</p>
        <Spinner color={c => c.white} isVisible={isLoading} />
      </Status>
    </Wrapper>
  )
}

Newsletter.propTypes = {
  placeholder: PropTypes.string,
  action: PropTypes.string,
  loading: PropTypes.string,
  success: PropTypes.string,
  error: PropTypes.string
}

Newsletter.defaultProps = {
  placeholder: 'satoshi.nakamoto@email.com',
  action: 'Sign me up',
  loading: 'Configuring your newsletter...',
  success: 'Thank you for signing up!',
  error: 'Network error, please try again.'
}

export default Newsletter
