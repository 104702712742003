import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  margin-left: ${props => props.theme.sizes.edge};
  width: calc(100% - 2 * ${props => props.theme.sizes.edge});
  position: relative;
  overflow: hidden;
  border-radius: calc(${props => props.theme.sizes.edge} * 1 / 2);
  background: ${props => props.theme.gradients.dark};
  padding: calc(${props => props.theme.sizes.edge} * 3 / 2);

  &[data-padded='false'] {
    margin: 0;
    width: 100%;
  }

  ${props =>
    props.theme.isDark &&
    css`
      background: ${props => props.theme.colors.platform};
      border: 1px solid ${props => props.theme.colors.border};
    `}
`

const Data = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    z-index: 10;
    height: 13px;
    width: 13px;
    border-radius: 50%;
    border: 2px dotted ${props => props.theme.colors.white};
  }
  &[data-custom="true"]{
    &:before{
      content: '${props => props.symbol}';
      border-radius: none;
      border: none;
    }
  }
`

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: calc(${props => props.theme.sizes.edge} * 2);
  color: ${props => props.theme.colors.whiteAbsolute};
  & > p {
    line-height: 1.4;
    margin: 0;
    font-size: 10pt;
    font-weight: 600;
    color: ${props => props.theme.colors.whiteAbsolute};
    a {
      border-bottom: 1px dashed ${props => props.theme.colors.whiteAbsolute};
      &:hover,
      &:active {
        border-bottom: 1px solid ${props => props.theme.colors.whiteAbsolute};
      }
    }
  }
`

function Incentive ({ symbol, isSelfPadded, children }) {
  return (
    <Wrapper data-padded={isSelfPadded}>
      <Data symbol={symbol} data-custom={!_.isNil(symbol)}>
        <Content>{children}</Content>
      </Data>
    </Wrapper>
  )
}

Incentive.propTypes = {
  emoji: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isSelfPadded: PropTypes.bool
}

Incentive.defaultProps = {
  emoji: null,
  isSelfPadded: true
}

export default Incentive
