import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Network as NetworkAtom } from '../../../../atoms'

const Wrapper = styled.div`
  grid-column: span 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

function Network ({ data, column, theme }) {
  return (
    <Wrapper size={_.get(column, 'weight')} data-theme={theme}>
      <NetworkAtom id={_.get(data, 'value')} />
    </Wrapper>
  )
}

Network.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.number
  }),
  column: PropTypes.shape({
    size: PropTypes.number
  })
}

Network.defaultProps = {
  data: {
    value: 1
  },
  column: {
    size: 1
  }
}

export default Network
