import _ from 'lodash'
import { useMemo, useRef, useEffect, useState, useCallback } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { findPageByRoute } from '@pods-finance/utils'

export function useRefresh () {
  const [version, setVersion] = useState(0)
  const refresh = useCallback(() => {
    setVersion(prev => prev + 1)
  }, [setVersion])
  return {
    refresh,
    version
  }
}

export function useOnClickOutside (handler, reference = null) {
  const temp = useRef()
  const ref = reference || temp

  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) return
      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])

  return [ref]
}

export function useWindowSize () {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  })

  useEffect(() => {
    function handleResize () {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

export function useAbortController () {
  const abortControllerRef = useRef()
  const getAbortController = useCallback(() => {
    if (!abortControllerRef.current && _.has(window, 'AbortController')) {
      abortControllerRef.current = new window.AbortController()
    }
    return abortControllerRef.current
  }, [])

  useEffect(() => {
    return () => {
      const controller = getAbortController()
      if (controller) controller.abort()
    }
  }, [getAbortController])

  const getSignal = useCallback(() => {
    const controller = getAbortController()
    return _.get(controller, 'signal')
  }, [getAbortController])

  const getAborted = useCallback(() => {
    const signal = getSignal()
    return _.isNil(signal) || signal.aborted
  }, [getSignal])

  return useMemo(() => ({ getSignal, getAborted }), [getSignal, getAborted])
}

export function useLocalStorage (key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      console.error('Local storage', error)
      return initialValue
    }
  })

  const setValue = useCallback(
    value => {
      try {
        setStoredValue(value)
        if (_.isNil(value)) window.localStorage.removeItem(key)
        window.localStorage.setItem(key, JSON.stringify(value))
      } catch (error) {
        console.error('Local storage', error)
      }
    },
    [key]
  )

  return [storedValue, setValue]
}

export function useRouteId (force = null) {
  const params = useParams()
  const result = useMemo(() => {
    if (
      _.isNilOrEmptyString(force) &&
      (_.isNil(params) || _.isNilOrEmptyString(_.get(params, 'id')))
    ) {
      return null
    }
    return String(force || _.get(params, 'id')).toLowerCase()
  }, [params, force])
  return result
}

export function usePageByRoute (pages) {
  const location = useLocation()
  const current = useMemo(
    () =>
      _.get(location, 'pathname')
        ? _.toString(_.get(location, 'pathname')).split('#')[0]
        : null,
    [location]
  )

  return findPageByRoute(current, pages)
}
