import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Theme from '@pods-finance/themes'
import { useTokenByNetworkId } from '@pods-finance/hooks'
import { TokenMultiDisplay } from '../../../../atoms'

const WrapperPartial = styled.div`
  grid-column: span 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

const Left = styled.div`
  height: 100%;
  margin-right: 10px;
  padding-left: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const Data = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  *[data-component='token-icons'] {
    display: none;
  }
`

const Subtitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 4px;
  p {
    font-size: 10pt !important;
    font-weight: 600 !important;
    color: ${props => props.theme.colors.contentMedium} !important;
    margin: 0;
  }

  & > p {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    &:after {
      content: '-';
      display: inline-flex;
      margin: auto 2px;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
`

const Wrapper = styled(WrapperPartial)`
  &[data-theme="${Theme.attributes.table.theme.activitySlim}"]{

    ${Subtitle} > p{
    display: none;
    }
  }




`

function Pod ({ data, column, theme }) {
  const { value: tokens } = useTokenByNetworkId(
    _.get(data, 'tokens'),
    _.get(data, 'tokens.0.networkId')
  )

  return (
    <Wrapper size={_.get(column, 'weight')} data-theme={theme}>
      <Left>
        <TokenMultiDisplay
          tokens={tokens.slice(0, 2)}
          isSelfPadded={false}
          isLabelIncluded={false}
        />
      </Left>
      <Data>
        <TokenMultiDisplay tokens={tokens.slice(0, 2)} isSelfPadded={false} />
        <Subtitle>
          <p>
            {_.get(data, 'localization').slice(0, 1)}{' '}
            {_.get(data, 'classification')}
          </p>
          <p>{_.get(data, 'strikePrice')}</p>
          <p>{_.get(data, 'expiration')}</p>
        </Subtitle>
      </Data>
    </Wrapper>
  )
}

Pod.propTypes = {
  data: PropTypes.shape({
    tokens: PropTypes.arrayOf(PropTypes.shape({})),
    classification: PropTypes.string,
    strikePrice: PropTypes.string,
    expiration: PropTypes.string
  }),
  column: PropTypes.shape({
    size: PropTypes.number
  })
}

Pod.defaultProps = {
  data: {
    tokens: [],
    classification: 'Put',
    strikePrice: null,
    expiration: null
  },
  column: {
    size: 1
  }
}

export default Pod
