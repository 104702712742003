export default {
  action: {
    invest: 'invest',
    hedge: 'hedge',
    pool: 'pool',
    history: 'history'
  },
  option: {
    put: 'put',
    call: 'call'
  },
  layout: {
    cell: {
      Actions: 'Actions',
      Network: 'Network',
      Pod: 'Pod',
      PodAsset: 'PodAsset',
      PodPair: 'PodPair',
      Price: 'Price',
      Reward: 'Reward',
      Text: 'Text',
      TextDeck: 'TextDeck',
      Timestamp: 'Timestamp',

      TransactionHeader: 'TransactionHeader',
      TransactionActions: 'TransactionActions',
      TransactionAmount: 'TransactionAmount'
    }
  }
}
