import IconADAI from '../assets/tokens/ADAI.png'
import IconAUSDC from '../assets/tokens/AUSDC.png'
import IconAVAX from '../assets/tokens/AVAX.png'
import IconBNB from '../assets/tokens/BNB.png'
import IconBUSD from '../assets/tokens/BUSD.png'
import IconDAI from '../assets/tokens/DAI.png'
import IconELFUSDC from '../assets/tokens/ELFUSDC.png'
import IconETH from '../assets/tokens/ETH.png'
import IconFTM from '../assets/tokens/FTM.png'
import IconIDLEUSDC from '../assets/tokens/IDLEUSDC.png'
import IconLINK from '../assets/tokens/LINK.png'
import IconMAI from '../assets/tokens/MAI.png'
import IconMATIC from '../assets/tokens/MATIC.png'
import IconSUSHI from '../assets/tokens/SUSHI.png'
import IconUSDC from '../assets/tokens/USDC.png'
import IconWBTC from '../assets/tokens/WBTC.png'
import SDK from '@pods-finance/sdk'
import _ from 'lodash'
import networks from '../networks'

export const trust = address =>
  `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${_.get(
    address,
    'address'
  )}/logo.png`

const keys = {
  LINK: 'LINK',
  WBTC: 'WBTC',
  ETH: 'ETH',
  WETH: 'WETH',
  USDC: 'USDC',
  DAI: 'DAI',
  MIMATIC: 'MIMATIC',
  AUSDC: 'AUSDC',
  ADAI: 'ADAI',
  ALINK: 'ALINK',
  SUSHI: 'SUSHI',
  PMDAI: 'PMDAI',
  PMETH: 'PMETH',
  PMUSDC: 'PMUSDC',
  AMUSDC: 'AMUSDC',
  AMDAI: 'AMDAI',
  MAUSDC: 'MAUSDC',
  WMATIC: 'WMATIC',
  MATIC: 'MATIC',
  TEST: 'TEST',
  IDLEUSDCYIELD: 'IDLEUSDCYIELD',
  'EPYVUSDC-29OCT21': 'EPYVUSDC-29OCT21',
  'EPYVUSDC-28JAN22': 'EPYVUSDC-28JAN22',
  'EPYVUSDC-17DEC21': 'EPYVUSDC-17DEC21',
  BNB: 'BNB',
  WBNB: 'WBNB',
  BUSD: 'BUSD',
  AVAX: 'AVAX',
  WAVAX: 'WAVAX',
  FTM: 'FTM',
  WFTM: 'WFTM'
}

const tokens = {
  keys,
  general: {
    [keys.ETH]: {
      icon: () => IconETH,
      title: 'Ethereum',
      tag: 'underlying',
      decimals: 18
    },
    [keys.WETH]: {
      icon: () => IconETH,
      title: 'Wrapped Ethereum',
      tag: 'underlying'
    },
    [keys.MATIC]: {
      icon: () => IconMATIC,
      title: 'Matic',
      tag: 'underlying'
    },
    [keys.WMATIC]: {
      icon: () => IconMATIC,
      title: 'Wrapped Matic',
      tag: 'underlying'
    },
    [keys.WBTC]: {
      icon: () => IconWBTC,
      title: 'Wrapped Bitcoin',
      tag: 'underlying'
    },
    [keys.LINK]: {
      icon: () => IconLINK,
      title: 'Chainlink',
      tag: 'underlying'
    },
    [keys.USDC]: {
      icon: () => IconUSDC,
      title: 'USD Coin',
      tag: 'collateral'
    },
    [keys.MIMATIC]: {
      icon: () => IconMAI,
      title: 'MAI Stablecoin',
      alias: 'MAI',
      tag: 'collateral'
    },
    [keys.DAI]: {
      icon: () => IconDAI,
      title: 'DAI Stablecoin',
      tag: 'collateral'
    },
    [keys.AUSDC]: {
      icon: () => IconAUSDC,
      title: 'Aave USD Coin',
      tag: 'collateral'
    },
    [keys.ADAI]: {
      icon: () => IconADAI,
      title: 'Aave DAI Stablecoin',
      tag: 'collateral'
    },
    [keys.ALINK]: {
      icon: () => IconLINK
    },
    [keys.SUSHI]: {
      icon: () => IconSUSHI,
      title: 'Sushiswap',
      tag: 'underlying'
    },
    [keys.AMUSDC]: {
      symbol: 'amUSDC',
      icon: () => IconAUSDC,
      title: 'Aave USD Coin Matic',
      tag: 'collateral'
    },
    [keys.AMDAI]: {
      symbol: 'amDAI',
      icon: () => IconADAI,
      title: 'Aave DAI Coin Matic',
      tag: 'collateral'
    },
    [keys['EPYVUSDC-29OCT21']]: {
      icon: () => IconELFUSDC,
      symbol: 'ePyvUSDC-29OCT21',
      alias: 'ePUSDC',
      title: 'Element Finance ePyvUSDC 29 OCT 2021'
    },
    [keys['EPYVUSDC-28JAN22']]: {
      icon: () => IconELFUSDC,
      symbol: 'EPYVUSDC-28JAN22',
      alias: 'ePUSDC',
      title: 'Element Finance EPYVUSDC 28 JAN 2022'
    },
    [keys['EPYVUSDC-17DEC21']]: {
      icon: () => IconELFUSDC,
      symbol: 'ePyvUSDC-17DEC21',
      alias: 'ePUSDC',
      title: 'Element Finance EPYVUSDC 17 DEC 2021'
    },
    [keys.IDLEUSDCYIELD]: {
      icon: () => IconIDLEUSDC,
      title: 'Idle USDC',
      symbol: 'IDLEUSDCYIELD',
      alias: 'iUSDC',
      tag: 'collateral'
    }
  },
  [networks.mainnet]: {
    [keys.WETH]: {
      symbol: SDK.constants.networks.mainnet.token.symbol,
      address: SDK.constants.networks.mainnet.token.utility[0]
    }
  },
  [networks.kovan]: {
    [keys.WETH]: {
      symbol: SDK.constants.networks.kovan.token.symbol,
      address: SDK.constants.networks.kovan.token.utility[0]
    },
    [keys.TEST]: {
      icon: () => IconETH,
      title: 'Pods Test Coin'
    }
  },
  [networks.matic]: {
    [keys.WMATIC]: {
      symbol: SDK.constants.networks.matic.token.symbol,
      address: SDK.constants.networks.matic.token.utility[0]
    },
    [keys.MAUSDC]: {
      icon: () => IconAUSDC,
      title: 'Aave USD Coin Matic',
      tag: 'collateral'
    }
  },
  [networks.bsc]: {
    [keys.BNB]: {
      icon: () => IconBNB,
      title: 'BNB Coin'
    },
    [keys.WBNB]: {
      icon: () => IconBNB,
      title: 'Wrapped BNB Coin',
      symbol: SDK.constants.networks.bsc.token.symbol,
      address: SDK.constants.networks.bsc.token.utility[0]
    },
    [keys.BUSD]: {
      icon: () => IconBUSD,
      title: 'BUSD',
      symbol: 'BUSD'
    }
  },
  [networks.fantom]: {
    [keys.FTM]: {
      icon: () => IconFTM,
      title: 'FTM'
    },
    [keys.WFTM]: {
      icon: () => IconFTM,
      title: 'Wrapped FTM',
      symbol: SDK.constants.networks.fantom.token.symbol,
      address: SDK.constants.networks.fantom.token.utility[0]
    }
  },
  [networks.avalanche]: {
    [keys.AVAX]: {
      icon: () => IconAVAX,
      title: 'AVAX'
    },
    [keys.WAVAX]: {
      icon: () => IconAVAX,
      title: 'Wrapped AVAX',
      symbol: SDK.constants.networks.avalanche.token.symbol,
      address: SDK.constants.networks.avalanche.token.utility[0]
    }
  }
}

export default tokens
