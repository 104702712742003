import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import { getInterpretedToken } from '@pods-finance/utils'

export function useTokenByNetworkId (source, networkId) {
  const builder = useCallback(item => getInterpretedToken(item, networkId), [
    networkId
  ])

  const result = useMemo(() => {
    if (_.isArray(source) && source.every(item => _.isString(item))) {
      return source
        .map(item => builder({ symbol: item }))
        .filter(item => !_.isNil(item))
    }

    if (_.isArray(source)) {
      return source.map(item => builder(item)).filter(item => !_.isNil(item))
    }

    if (_.isObject(source)) {
      return builder(source)
    }

    if (_.isString(source)) {
      return builder({ symbol: source })
    }

    return null
  }, [source, builder])

  return { value: result, get: builder }
}
