import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { networks } from '@pods-finance/globals'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 4px;
`

const Icon = styled.img`
  height: 26px;
  width: 26px;
  object-fit: cover;
  margin-right: 12px;
`

const Title = styled.p`
  font-size: 11pt;
  font-weight: 600;
  color: ${props => props.theme.colors.dark};
  margin: 0;
`

export default function NetworkDisplay ({ id }) {
  return (
    <Wrapper>
      <Icon src={_.get(networks._data, `${id}.icon`)} />
      <Title>{_.get(networks._data, `${id}.name`)}</Title>
    </Wrapper>
  )
}

NetworkDisplay.propTypes = {
  id: PropTypes.number.isRequired,
  isSelectable: PropTypes.bool,
  isActive: PropTypes.bool
}

NetworkDisplay.defaultProps = {
  isSelectable: false,
  isActive: false
}
