import * as constants from './constants'
import * as routes from './routes'
import networks, { subgraphed, etherscaned } from './networks'
import tokens from './tokens'
import attributes from './attributes'
import { ENVIRONMENT } from './environment'
import words from './words'

export * from './constants'

export {
  ENVIRONMENT,
  tokens,
  networks,
  subgraphed,
  etherscaned,
  routes,
  words,
  attributes
}

export default {
  ...constants,
  routes,
  tokens,
  words,
  attributes,
  networks,
  subgraphed,
  etherscaned,
  ENVIRONMENT
}
