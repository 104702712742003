import 'normalize.css/normalize.css'

import React, { useCallback } from 'react'
import {
  ThemeProvider as Provider,
  createGlobalStyle,
  css
} from 'styled-components'

import animations from './animations'
import attributes from './attributes'
import colors, { gradients, fills, toColors, toGradients } from './colors'
import fonts from './fonts'
import medias from './medias'
import sizes from './sizes'
import styles, { toStyles } from './styles'
import extensions from './extensions'

const CommonStyle = css`
  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }

  html,
  body {
    height: 100%;
    width: 100%;
    padding: 0rem;
    margin: 0rem;
    overflow-x: hidden;
    overflow-y: hidden;
    font-family: ${props => props.theme.fonts.primary};
    font-size: 16px;
    overscroll-behavior: none;
    background-color: ${props => props.theme.colors.dark};

    #root {
      overflow-x: hidden;
    }
  }

  html {
    color: ${props => props.theme.colors.dark};
    cursor: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  body {
    overflow-y: auto;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button,
  input,
  select,
  textarea {
    appearance: none;
    border: none;
    font-family: inherit;
    outline: none;
  }

  @keyframes autofill {
    to {
      background-color: ${props => props.theme.colors.white};
    }
  }

  input[type='number']:-webkit-inner-spin-button,
  input[type='number']:-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
  }
  input:-webkit-autofill {
    animation-fill-mode: both;
    animation-name: autofill;
  }

  #WEB3_CONNECT_MODAL_ID > div {
    z-index: 99999;
  }
`

const GlobalStyle = createGlobalStyle`
  ${CommonStyle}
`

const TeaserGlobalStyle = createGlobalStyle`
  ${CommonStyle}
`

const LandingGlobalStyle = createGlobalStyle`
  ${CommonStyle}
`

const PlatformGlobalStyle = createGlobalStyle`
  ${CommonStyle}

  html, body{
    background-color: ${props => props.theme.colors.white} !important;
  }
`

function ThemeProvider ({ isDark, children }) {
  const params = useCallback(
    isDark => ({
      isDark,
      animations,
      attributes,
      colors: toColors(isDark),
      gradients: toGradients(isDark),
      fills,
      extensions,
      fonts,
      medias,
      sizes,
      styles: toStyles(isDark)
    }),
    []
  )

  return <Provider theme={{ ...params(isDark) }}>{children}</Provider>
}

export {
  animations,
  attributes,
  colors,
  fonts,
  gradients,
  fills,
  medias,
  sizes,
  styles,
  extensions,
  GlobalStyle,
  TeaserGlobalStyle,
  LandingGlobalStyle,
  PlatformGlobalStyle,
  ThemeProvider as Provider
}
