import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { networks } from '@pods-finance/globals'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 34px;
  padding: 8px;
  border-radius: 4px;
  background: ${props =>
    props.theme.isDark ? 'rgba(0, 0, 0, 0.2)' : props.theme.colors.white};
  outline: 1px solid
    ${props =>
      props.theme.isDark ? 'rgba(0, 0, 0, 0.2)' : props.theme.colors.border};

  &:not([data-active='true']) {
    &[data-selectable='true'] {
      cursor: pointer;
      &:hover,
      &:active {
        background: ${props =>
          props.theme.isDark
            ? 'rgba(255, 255, 255, 0.1)'
            : props.theme.colors.platform};
      }
    }
  }

  &[data-active='true'] {
    outline: 1px solid ${props => props.theme.colors.border};
    background: ${props =>
      props.theme.isDark
        ? 'rgba(255, 255, 255, 0.1)'
        : props.theme.colors.platform};
  }
`

const Icon = styled.img`
  height: 16px;
  width: 16px;

  object-fit: cover;
  margin-right: 7px;
`

const Title = styled.p`
  font-size: 10pt;
  font-weight: 600;
  color: ${props => props.theme.colors.dark};
  margin: 0;
`

export default function Network ({ id, isActive, isSelectable }) {
  return (
    <Wrapper data-active={isActive} data-select={isSelectable}>
      <Icon src={_.get(networks._data, `${id}.icon`)} />
      <Title>{_.get(networks._data, `${id}.name`)}</Title>
    </Wrapper>
  )
}

Network.propTypes = {
  id: PropTypes.number.isRequired,
  isSelectable: PropTypes.bool,
  isActive: PropTypes.bool
}

Network.defaultProps = {
  isSelectable: false,
  isActive: false
}
