import _ from 'lodash'
import dayjs from 'dayjs'
import BigNumber from 'bignumber.js'
import { toNumeralPrice, toQuantity } from '@pods-finance/utils'

import Option from './Option'
import { macros } from '@pods-finance/globals'

export const ActionType = {
  Buy: 'Buy',
  Sell: 'Sell',
  Mint: 'Mint',
  Unmint: 'Unmint',
  Resell: 'Resell',
  AddLiquidity: 'AddLiquidity',
  RemoveLiquidity: 'RemoveLiquidity',
  Exercise: 'Exercise',
  Withdraw: 'Withdraw',
  TransferFrom: 'TransferFrom',
  TransferTo: 'TransferTo'
}

/**
 * @typedef IValue
 * @property {BigNumber} raw
 * @property {BigNumber} humanized
 */
class Action {
  /**
   * Fixed Data
   */

  _element = {}

  _inputTokenA = null
  _inputTokenB = null
  _outputTokenA = null
  _outputTokenB = null

  _option = null
  _preview = {}

  get element () {
    return this._element
  }

  get preview () {
    return this._preview
  }

  get option () {
    return this._option
  }

  get inputTokenA () {
    return this._inputTokenA
  }

  get inputTokenB () {
    return this._inputTokenB
  }

  get outputTokenA () {
    return this._outputTokenA
  }

  get outputTokenB () {
    return this._outputTokenB
  }

  get id () {
    return _.get(this._element, 'id')
  }

  get networkId () {
    return _.get(this._element, 'networkId')
  }

  get type () {
    return _.get(this._element, 'type')
  }

  get hash () {
    return _.get(this._element, 'hash')
  }

  get from () {
    return _.get(this._element, 'from')
  }

  get timestamp () {
    return _.get(this._element, 'timestamp')
  }

  constructor (source) {
    this._element = source
    try {
      this._option = new Option({
        value: source.option,
        warning: null,
        isLoading: false,
        isCurated: false
      })

      this._inputTokenA = this._element.getInputTokenAValue()
      this._inputTokenB = this._element.getInputTokenBValue()
      this._outputTokenA = this._element.getOutputTokenAValue()
      this._outputTokenB = this._element.getOutputTokenBValue()

      this._preview = this.configurePreview()
    } catch (error) {
      console.error('Action', error)
    }
  }

  fromSDK () {
    return this._element
  }

  configurePreview () {
    let title, amountTransacted, amountTransactedInfo

    const expiration = dayjs(parseInt(this.option.expiration) * 1000).format(
      'MMM DD'
    )
    const timestamp = dayjs(parseInt(this.timestamp) * 1000).format(
      'MMM DD, hh:mm A'
    )

    switch (this.type) {
      case ActionType.Buy: {
        title = 'Buy Options'
        amountTransacted = toQuantity(
          toNumeralPrice(this.outputTokenA.humanized, false),
          'option'
        )
        amountTransactedInfo = `Paid ${toNumeralPrice(
          this.inputTokenB.humanized
        )} in premium`
        break
      }
      case ActionType.Sell: {
        title = 'Write Options'
        if (this.option.isPut()) {
          amountTransacted = toQuantity(
            toNumeralPrice(
              this.inputTokenB.humanized.dividedBy(
                this.option.strikePrice.humanized
              ),
              false
            ),
            'option'
          )

          amountTransactedInfo = `Locked ${
            this.option.strike.symbol
          } ${toNumeralPrice(
            this.inputTokenB.humanized
          )} to earn ${toNumeralPrice(this.outputTokenB.humanized)}`
        } else if (this.option.isCall()) {
          amountTransacted = toQuantity(
            toNumeralPrice(this.inputTokenA.humanized, false),
            'option'
          )

          amountTransactedInfo = `Locked ${
            this.option.underlying.symbol
          } ${toNumeralPrice(
            this.inputTokenA.humanized
          )} to earn ${toNumeralPrice(this.outputTokenB.humanized)}`
        }
        break
      }
      case ActionType.Resell: {
        title = 'Resell Options'

        amountTransacted = toQuantity(
          toNumeralPrice(this.inputTokenA.humanized, false),
          'option'
        )
        amountTransactedInfo = `Resold options to earn ${toNumeralPrice(
          this.outputTokenB.humanized
        )}`
        break
      }
      case ActionType.Exercise: {
        title = 'Exercise Options'

        if (this.option.isPut()) {
          amountTransacted = toQuantity(
            toNumeralPrice(this.inputTokenA.humanized, false),
            'option'
          )

          amountTransactedInfo = `Received ${toNumeralPrice(
            this.outputTokenB.humanized
          )} ${this.option.strike.symbol} for ${toNumeralPrice(
            this.inputTokenA.humanized,
            false
          )} ${this.option.underlying.symbol}`
        } else if (this.option.isCall()) {
          amountTransacted = toQuantity(
            toNumeralPrice(this.inputTokenA.humanized, false),
            'option'
          )

          amountTransactedInfo = `Received ${toNumeralPrice(
            this.outputTokenA.humanized
          )} ${this.option.underlying.symbol} for ${toNumeralPrice(
            this.inputTokenB.humanized,
            false
          )} ${this.option.strike.symbol}`
        }

        break
      }
      case ActionType.Withdraw: {
        title = 'Withdraw'

        amountTransacted = [
          [
            this.outputTokenA.humanized,
            `${toNumeralPrice(this.outputTokenA.humanized, false)} ${
              this.option.underlying.symbol
            }`
          ],
          [
            this.outputTokenB.humanized,
            `${toNumeralPrice(this.outputTokenB.humanized, false)} ${
              this.option.strike.symbol
            }`
          ]
        ]
          .filter(pair => !pair[0].isZero())
          .map(pair => pair[1])
          .join(' and ')

        amountTransactedInfo = 'Withdrawn'
        break
      }
      case ActionType.Mint: {
        title = 'Mint Options'

        if (this.option.isPut()) {
          amountTransacted = toQuantity(
            toNumeralPrice(this.outputTokenA.humanized, false),
            'option'
          )

          amountTransactedInfo = `Locked ${toNumeralPrice(
            this.inputTokenB.humanized
          )} in ${this.option.strike.symbol}`
        } else if (this.option.isCall()) {
          amountTransacted = toQuantity(
            toNumeralPrice(this.outputTokenA.humanized, false),
            'option'
          )

          amountTransactedInfo = `Locked ${toNumeralPrice(
            this.inputTokenA.humanized,
            false
          )} ${this.option.underlying.symbol} to mint`
        }

        break
      }
      case ActionType.Unmint: {
        title = 'Unmint Options'
        amountTransacted = toQuantity(
          toNumeralPrice(this.inputTokenA.humanized, false),
          'option'
        )
        if (this.option.isPut()) {
          amountTransactedInfo = `Unlocked ${toNumeralPrice(
            this.outputTokenB.humanized,
            false
          )} in ${this.option.strike.symbol}`
        } else if (this.option.isCall()) {
          amountTransactedInfo = `Unlocked ${toNumeralPrice(
            this.outputTokenA.humanized,
            false
          )} in ${this.option.underlying.symbol}`
        }
        break
      }
      case ActionType.AddLiquidity: {
        title = 'Add Liquidity'

        if (this.option.isPut()) {
          const optionValue = this.inputTokenA.humanized.times(
            this.option.strikePrice.humanized
          )

          const split = [
            [
              this.inputTokenA.humanized,
              `${toQuantity(
                toNumeralPrice(this.inputTokenA.humanized, false),
                'option'
              )} (from ${toNumeralPrice(optionValue, false)} ${
                this.option.strike.symbol
              } as collateral)`
            ],
            [
              this.inputTokenB.humanized,
              `${toNumeralPrice(this.inputTokenB.humanized, false)} ${
                this.option.strike.symbol
              }`
            ]
          ]
            .filter(pair => !pair[0].isZero())
            .map(pair => pair[1])
            .join(' and ')

          amountTransacted = `${toNumeralPrice(
            optionValue.plus(this.inputTokenB.humanized)
          )} ${this.option.strike.symbol}`
          amountTransactedInfo = `${split} provided to the pool`
        } else if (this.option.isCall()) {
          const split = [
            [
              this.inputTokenA.humanized,
              `${toNumeralPrice(this.inputTokenA.humanized, false)} ${
                this.option.underlying.symbol
              }`
            ],
            [
              this.inputTokenB.humanized,
              `${toNumeralPrice(this.inputTokenB.humanized, false)} ${
                this.option.strike.symbol
              }`
            ]
          ]
            .filter(pair => !pair[0].isZero())
            .map(pair => pair[1])
            .join(' and ')

          amountTransacted = split
          amountTransactedInfo = 'Provided to the pool'
        }

        break
      }
      case ActionType.RemoveLiquidity: {
        title = 'Remove Liquidity'

        const split = [
          [
            this.outputTokenA.humanized,
            `${toQuantity(
              toNumeralPrice(this.outputTokenA.humanized, false),
              'option'
            )}`
          ],
          [
            this.outputTokenB.humanized,
            `${toNumeralPrice(this.outputTokenB.humanized, false)} ${
              this.option.strike.symbol
            }`
          ]
        ]
          .filter(pair => !pair[0].isZero())
          .map(pair => pair[1])
          .join(' and ')

        const total = (() => {
          if (this.outputTokenA.humanized.isZero()) {
            return toNumeralPrice(this.outputTokenB.humanized)
          } else {
            if (this.option.isPut()) {
              return toNumeralPrice(
                this.outputTokenB.humanized.plus(
                  this.outputTokenA.humanized.times(
                    this.option.strikePrice.humanized
                  )
                )
              )
            } else if (this.option.isCall()) {
              return `${toNumeralPrice(this.outputTokenA.humanized, false)} ${
                this.option.underlying.symbol
              } and ${toNumeralPrice(this.outputTokenB.humanized)}`
            }
          }
        })()

        amountTransacted = split
        amountTransactedInfo = `Removed an equivalent of ${total}`

        const fees = this.getMetadataFeeValue()
        if (
          !_.isNil(fees) &&
          !fees.isZero() &&
          fees.isGreaterThanOrEqualTo(macros.MINIMUM_BALANCE_AMOUNT)
        ) {
          amountTransactedInfo += ` and earned ${toNumeralPrice(
            fees
          )} in additional fees`
        }

        break
      }
      case ActionType.TransferTo: {
        title = 'Receive Options'

        amountTransacted = toQuantity(
          toNumeralPrice(this.outputTokenA.humanized, false),
          'option'
        )

        amountTransactedInfo = 'Received from another wallet'
        break
      }
      case ActionType.TransferFrom: {
        title = 'Send Options'
        amountTransacted = toQuantity(
          toNumeralPrice(this.inputTokenA.humanized, false),
          'option'
        )

        amountTransactedInfo = 'Sent to another wallet'

        break
      }
      default: {
        title = 'Transaction'
        amountTransacted = ''
        amountTransactedInfo = ''
      }
    }

    return {
      title,
      type: this.type,
      amountTransacted,
      amountTransactedInfo,
      underlying: this.option.underlying,
      strike: this.option.strike,
      tokenA: this.option.pool.tokenA,
      tokenB: this.option.pool.tokenB,
      classification: this.option.isPut() ? 'Put' : 'Call',
      expiration,
      timestamp
    }
  }

  getMetadataFeeAValue () {
    try {
      if (!this._element) return null

      const result = this._element.getMetadataFeeAValue()

      return _.get(result, 'humanized')
    } catch (e) {
      return null
    }
  }

  getMetadataFeeBValue () {
    try {
      if (!this._element) return null

      const result = this._element.getMetadataFeeAValue()

      return _.get(result, 'humanized')
    } catch (e) {
      return null
    }
  }

  getMetadataFeeValue () {
    try {
      if (!this._element) return null

      let feeA = this.getMetadataFeeAValue()
      let feeB = this.getMetadataFeeBValue()

      if (_.isNil(feeA)) feeA = new BigNumber(0)
      if (_.isNil(feeB)) feeB = new BigNumber(0)

      return feeA.plus(feeB)
    } catch (e) {
      console.error('[Action]', e)
      return new BigNumber(0)
    }
  }
}

Action.ActionType = ActionType

export default Action
