import AssetArbitrum from '../assets/networks/arbitrum.svg'
import AssetAvalanche from '../assets/networks/avalanche.png'
import AssetBSC from '../assets/networks/bsc.png'
import AssetEthereum from '../assets/networks/ethereum_purple.png'
import AssetFantom from '../assets/networks/fantom.png'
import AssetKovan from '../assets/networks/ethereum_gray.png'
import AssetMatic from '../assets/networks/matic.png'
import AssetOptimism from '../assets/networks/optimism.png'
import SDK from '@pods-finance/sdk'
import _ from 'lodash'

const networks = {
  mainnet: SDK.constants.networks.mainnet.networkId,
  goerli: SDK.constants.networks.goerli.networkId,
  kovan: SDK.constants.networks.kovan.networkId,
  matic: SDK.constants.networks.matic.networkId,
  arbitrum: SDK.constants.networks.arbitrum.networkId,
  optimism: SDK.constants.networks.optimism.networkId,
  fantom: SDK.constants.networks.fantom.networkId,
  bsc: SDK.constants.networks.bsc.networkId,
  avalanche: SDK.constants.networks.avalanche.networkId,

  _supported: [
    SDK.constants.networks.mainnet.networkId, // mainnet
    SDK.constants.networks.kovan.networkId, // kovan
    SDK.constants.networks.matic.networkId, // matic
    SDK.constants.networks.arbitrum.networkId // arbitrum
  ],
  _supported_factory: [
    SDK.constants.networks.mainnet.networkId, // mainnet
    SDK.constants.networks.kovan.networkId, // kovan
    SDK.constants.networks.goerli.networkId, // goerli
    SDK.constants.networks.matic.networkId, // matic
    SDK.constants.networks.arbitrum.networkId, // arbitrum
    SDK.constants.networks.optimism.networkId, // optimism
    SDK.constants.networks.fantom.networkId, // fantom
    SDK.constants.networks.bsc.networkId, // bsc
    SDK.constants.networks.avalanche.networkId // avalanche
  ]
}

function bindDataWithIcons (data) {
  const icons = {
    [networks.mainnet]: AssetEthereum,
    [networks.kovan]: AssetKovan,
    [networks.goerli]: AssetKovan,
    [networks.matic]: AssetMatic,
    [networks.arbitrum]: AssetArbitrum,
    [networks.optimism]: AssetOptimism,
    [networks.avalanche]: AssetAvalanche,
    [networks.bsc]: AssetBSC,
    [networks.fantom]: AssetFantom
  }

  Object.keys(icons).map(key => {
    data[key].icon = icons[key]
  })

  return data
}

networks._data = bindDataWithIcons(SDK.constants.networks)

export default networks

export function etherscaned (hash, networkId = networks.kovan, isTx = true) {
  if (_.isNilOrEmptyString(networkId) || _.isNilOrEmptyString(hash)) return ''
  try {
    if (isTx) {
      return new URL(
        `${_.get(networks, `_data[${networkId}].explorer`)}/tx/${hash}`
      )
    } else {
      return new URL(
        `${_.get(networks, `_data[${networkId}].explorer`)}/address/${hash}`
      )
    }
  } catch (e) {
    return ''
  }
}

export const subgraphed = (networkId = networks.kovan) =>
  _.get(networks, `_data[${networkId}].subgraph`)
