import _ from 'lodash'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

const Wrapper = styled.a`
  display: flex;
  align-items: center;
  padding: 0 ${props => props.theme.sizes.edge};
  user-select: none;

  &[target='_blank'] {
    &:hover,
    &:active {
      * {
        text-decoration: underline;
      }
    }
  }

  & > img,
  & > div.unknown {
    height: 26px;
    width: 26px;
    margin-right: 12px;
    background-color: ${props => props.theme.colors.platform};
    border-radius: 50%;
    flex-shrink: 0;
  }

  & > img {
    &[src=''],
    &:not([src]) {
      opacity: 0;
    }
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &:after {
      content: '💎';
      color: ${props => props.theme.colors.white};
    }
  }

  & > p {
    font-size: 11pt;
    font-weight: 600;
    color: ${props => props.theme.colors.dark};
    margin: 0 !important;
  }

  &[data-visible='false'] {
    display: none;
  }

  &[data-label='false'] {
    & > img,
    & > div {
      margin-right: 0;
    }
    *[data-component='token-label'] {
      display: none;
    }
  }

  ${props =>
    !props.isSelfPadded &&
    css`
      padding: 0;
    `}

  ${props => props.theme.medias.medium} {
    padding: 0 calc(${props => props.theme.sizes.edge} * 1 / 2);
    & > img {
      height: 26px;
      width: 26px;
      margin-right: 8px;
    }
    ${props =>
      !props.isSelfPadded &&
      css`
        padding: 0;
      `}
  }
`

function TokenDisplay ({ token, isSelfPadded, isLabelIncluded, isTarget }) {
  const symbol = useMemo(() => _.get(token, 'symbol'), [token])
  const icon = useMemo(() => _.get(token, 'icon'), [token])
  const title = useMemo(() => _.get(token, 'title'), [token])
  const alias = useMemo(() => _.get(token, 'alias') || _.get(token, 'symbol'), [
    token
  ])

  const target = useMemo(
    () =>
      isTarget
        ? `${_.get(token, 'scanner')}/token/${_.get(token, 'address')}`
        : null,
    [isTarget, token]
  )

  const extra = useMemo(() => {
    let value = {}

    if (!_.isNilOrEmptyString(title)) value.title = title

    if (!isTarget) {
      value = {
        ...value,
        as: 'div'
      }
    } else {
      value = {
        ...value,
        rel: 'noopener noreferrer',
        target: '_blank',
        href: target
      }
    }

    return value
  }, [target, title, isTarget])

  return (
    <Wrapper
      data-visible={!_.isNil(token) && !_.isNil(symbol)}
      data-label={isLabelIncluded}
      isSelfPadded={isSelfPadded}
      {...extra}
    >
      {icon ? <img src={icon} alt='' /> : <div className='unknown' />}{' '}
      <p data-component='token-label'>{alias}</p>
    </Wrapper>
  )
}

TokenDisplay.propTypes = {
  token: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    symbol: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    address: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    target: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  isTarget: PropTypes.bool,
  isSelfPadded: PropTypes.bool,
  isLabelIncluded: PropTypes.bool
}

TokenDisplay.defaultProps = {
  token: null,
  isSelfPadded: true,
  isLabelIncluded: true,
  isTarget: false
}

export default TokenDisplay
