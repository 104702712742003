import _ from 'lodash'
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Theme from '@pods-finance/themes'
import { Action } from '@pods-finance/models'
import { useTokenByNetworkId } from '@pods-finance/hooks'
import { TokenMultiDisplay } from '../../../../../atoms'

import IconInvest from '@material-ui/icons/SendRounded'
import IconHedge from '@material-ui/icons/SecurityRounded'
import IconPool from '@material-ui/icons/CategoryRounded'

const WrapperPartial = styled.div`
  grid-column: span 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 50px;
  width: 70px;
  border-radius: 6px;
  overflow: hidden;
  margin-right: calc(${props => props.theme.sizes.edge} * 1);
  background-color: ${props =>
    props.theme.isDark
      ? props.theme.colors.white
      : props.theme.colors.platform};
  & > svg {
    color: ${props => props.theme.colors.contentMedium};
    font-size: 12pt;
    position: relative;
    z-index: 20;
  }
`

const Data = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const Title = styled.p`
  ${props => props.theme.styles.tableParagraph};
  font-size: 12pt;
  font-weight: 600;
`

const Subtitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 6px;
  p {
    font-size: 10pt !important;
    font-weight: 600 !important;
    color: ${props => props.theme.colors.contentMedium} !important;
    margin: 0;
  }
  img {
    height: 18px !important;
    width: 18px !important;
    &:not(:first-child) {
      margin-left: -6px !important;
    }
  }

  & > p {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    &:before {
      content: '-';
      display: inline-flex;
      margin: auto 4px;
    }
  }
`

const Wrapper = styled(WrapperPartial)`
  &[data-theme="${Theme.attributes.table.theme.activitySlim}"]{
    ${IconWrapper}{
      display: none;
    }
    ${Subtitle} > p{
    display: none;
    }
  }




`

function TransactionHeader ({ data, column, theme }) {
  const { value: tokens } = useTokenByNetworkId(
    _.get(data, 'tokens'),
    _.get(data, 'tokens.0.networkId')
  )

  const Icon = useMemo(() => {
    switch (_.get(data, 'type')) {
      case Action.ActionType.Buy:
      case Action.ActionType.Exercise:
        return IconHedge
      case Action.ActionType.Wrtie:
      case Action.ActionType.Withdraw:
        return IconInvest
      case Action.ActionType.AddLiquidity:
      case Action.ActionType.RemoveLiquidity:
      case Action.ActionType.Mint:
      case Action.ActionType.Unmint:
        return IconPool
      default:
        return IconInvest
    }
  }, [data])

  return (
    <Wrapper size={_.get(column, 'weight')} data-theme={theme}>
      <IconWrapper>
        <Icon />
      </IconWrapper>
      <Data>
        <Title>{_.get(data, 'title')}</Title>
        <Subtitle>
          <TokenMultiDisplay
            tokens={tokens.slice(0, 2)}
            isMini
            isSelfPadded={false}
          />
          <p>{_.get(data, 'strikePrice')}</p>
          <p>
            {_.get(data, 'expiration')} {_.get(data, 'classification')}
          </p>
        </Subtitle>
      </Data>
    </Wrapper>
  )
}

TransactionHeader.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    tokens: PropTypes.arrayOf(PropTypes.shape({})),
    expiration: PropTypes.string,
    classification: PropTypes.string
  }),
  column: PropTypes.shape({
    size: PropTypes.number
  })
}

TransactionHeader.defaultProps = {
  data: {
    value: null,
    type: null,
    title: null,
    tokens: [],
    strikePrice: null,
    expiration: null,
    classification: 'Put'
  },
  column: {
    size: 1
  }
}

export default TransactionHeader
