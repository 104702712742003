import _ from 'lodash'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import dayjs from 'dayjs'
import { tokens } from '@pods-finance/globals'

const PriceContext = createContext(undefined)

export function usePriceContext () {
  return useContext(PriceContext)
}

export default function Provider ({ children, feeds }) {
  const value = usePricing({ feeds })

  return <PriceContext.Provider value={value}>{children}</PriceContext.Provider>
}

const start = dayjs()
  .subtract(1, 'week')
  .unix()

const interpretor = {
  spotInterpretor: response => {
    return response
      .json()
      .then(result => _.get(Object.values(_.toPlainObject(result)), '[0].usd'))
  },
  historicalInterpretor: response => {
    return response
      .json()
      .then(result =>
        _.toArray(_.get(result, 'prices'))
          .filter((_value, index) => index % 12 === 0)
          .map(price => _.get(price, '[1]'))
      )
      .catch(e => {
        console.error('Price', e)
        return [0]
      })
  }
}

const source = feeds => ({
  coingecko: {
    id: 'coingecko',
    feedsSpot: Object.keys(feeds).map(key => [
      key,
      `https://api.coingecko.com/api/v3/simple/price?ids=${_.get(
        feeds,
        `${key}.0`
      )}&vs_currencies=USD`
    ]),
    feedsHistorical: Object.keys(feeds).map(key => [
      key,
      `https://api.coingecko.com/api/v3/coins/${_.get(
        feeds,
        `${key}.0`
      )}/market_chart?vs_currency=usd&days=1`
    ])
  },
  coinpaprika: {
    id: 'coinpaprika',
    feedsSpot: Object.keys(feeds).map(key => [
      key,
      `https://api.coinpaprika.com/v1/tickers/${_.get(feeds, `${key}.1`)}`
    ]),
    feedsHistorical: Object.keys(feeds).map(key => [
      key,
      `https://api.coinpaprika.com/v1/tickers/${_.get(
        feeds,
        `${key}.1`
      )}/historical?start=${start}&interval=12h`
    ]),
    spotInterpretor: response => {
      return response.json().then(token => _.get(token, 'quotes.USD.price'))
    },
    historicalInterpretor: response => {
      return response
        .json()
        .then(timestamps => timestamps.map(timestamp => timestamp.price))
        .catch(e => {
          console.error('Price', e)
          return [0]
        })
    }
  }
})

const _API = feeds => source(feeds || []).coingecko

function usePricing ({ feeds }) {
  const API = _API(feeds)

  const [prices, setPrices] = useState({
    spot: null,
    historical: null,
    isLoading: true
  })

  const fetchPrices = useCallback(async () => {
    setPrices(prev => ({
      ...prev,
      isLoading: true
    }))

    const result = {
      spot: {},
      historical: {},
      isLoading: false
    }

    const spot = await Promise.all(
      API.feedsSpot
        .filter(data => !_.isNilOrEmptyString(data[1]))
        .map(data =>
          fetch(data[1])
            .then(getAPIPrice)
            .then(r => [data[0], r])
            .catch(error => {
              console.error('Price-feed error', error)
              return null
            })
        )
    )

    const historical = await Promise.all(
      API.feedsHistorical
        .filter(data => !_.isNilOrEmptyString(data[1]))
        .map(data =>
          fetch(data[1])
            .then(getAPIHistoricalPrices)
            .then(r => [data[0], r])
            .catch(error => {
              console.error('Price-feed error', error)
            })
        )
    )

    if (
      _.isNil(spot) ||
      spot.some(s => _.isNil(s)) ||
      _.isNil(historical) ||
      historical.some(h => _.isNil(h))
    ) {
      setPrices(result)
      return
    }

    /**
     *
     * Aliases
     *
     */

    ;[
      [tokens.keys.WMATIC, tokens.keys.MATIC],
      [tokens.keys.PMETH, tokens.keys.ETH],
      [tokens.keys.WETH, tokens.keys.ETH],
      [tokens.keys.WMATIC, tokens.keys.MATIC],
      [tokens.keys.ALINK, tokens.keys.LINK]
    ].forEach(([target, source]) => {
      const spotSource = spot.find(([key]) => key === source)
      if (spotSource) spot.push([target, spotSource[1]])

      const historicalSource = historical.find(([key]) => key === source)
      if (historicalSource) historical.push([target, historicalSource[1]])
    })

    /**
     *
     * Final
     *
     */

    spot.forEach(([key, value]) => {
      result.spot[key] = value
    })
    historical.forEach(([key, value]) => {
      result.historical[key] = [...value, result.spot[key]]
    })

    setPrices(result)
  }, [])

  useEffect(() => {
    fetchPrices()
  }, [fetchPrices])

  return prices
}

function getAPIPrice (response) {
  try {
    return interpretor.spotInterpretor(response).then(x => x)
  } catch (e) {
    console.error('Price feed', e)
    return 0
  }
}

function getAPIHistoricalPrices (response) {
  try {
    return interpretor.historicalInterpretor(response)
  } catch (e) {
    console.error('Price feed', e)
    return [0]
  }
}
