export default {
  warningTransactionAllowance: () =>
    'It seems that our app is having trouble figuring out your ERC20 allowance. Please reload the page to fix this problem. If this persists, please reach out on Discord! Thank you for your help and sorry for the inconvenince!',
  reasonTransactionRejected: () => 'Transaction rejected by the user',
  reasonTransactionFailed: () => 'Transaction failed',
  reasonTransactionExcepted: () =>
    'Transaction rejected because of a contract exception',
  errorTransactionFailed: prefix =>
    `${prefix} If you're experiencing any issues, please reach out on Discord.`
}
