import _ from 'lodash'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import IconMenu from '@material-ui/icons/MoreVertRounded'
import IconArrow from '@material-ui/icons/ArrowForwardRounded'

const Wrapper = styled.div`
  grid-column: span 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const Extra = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 52px;
  height: 52px;
  border-radius: 8px;
  margin-left: auto;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.border};
  box-shadow: none;
  transition: box-shadow 150ms;
  cursor: pointer;

  & > svg {
    font-size: 16pt;
    color: ${props => props.theme.colors.dark};
  }

  &:hover,
  &:active {
    box-shadow: ${props => props.theme.styles.boxShadowHover};
    transition: box-shadow 250ms;
  }
`

function TransactionActions ({ column, data }) {
  const icon = useMemo(() => _.get(data, 'icon') || 'menu', [data])
  return (
    <Wrapper size={_.get(column, 'weight')}>
      <Extra to='' data-component='action-extra'>
        {icon === 'menu' ? <IconMenu /> : null}
        {icon === 'arrow' ? <IconArrow /> : null}
      </Extra>
    </Wrapper>
  )
}

TransactionActions.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.string
  }).isRequired
}

TransactionActions.defaultTypes = {
  data: {
    value: ''
  }
}

export default TransactionActions
