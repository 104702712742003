import _ from 'lodash'
import BigNumber from 'bignumber.js'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import duration from 'dayjs/plugin/duration'
import numeral from 'numeral'
import { matchPath } from 'react-router-dom'
import { macros, networks, tokens, words } from '@pods-finance/globals'

dayjs.extend(relativeTime)
dayjs.extend(duration)

/**
 * Reduces a ethereum address to a smaller format
 * @param {string} fullAddress
 * @return {string|null}
 */
export function beautifyAddress (fullAddress, start = 6, end = 6) {
  if (!fullAddress) return null
  const addressSize = 42

  if (end - start >= addressSize) {
    return fullAddress
  }

  const newFullAddress =
    fullAddress.substr(0, start) +
    '...' +
    fullAddress.substr(fullAddress.length - end, fullAddress.length)

  return newFullAddress
}

export function getAddresses (networkId, ledger) {
  if (!_.isNil(networkId) && _.has(ledger, networkId)) {
    const sanitized = { ...ledger[networkId] }

    return {
      archive: [],
      puts: [],
      calls: [],
      manager: null,
      ...sanitized
    }
  }
  return {
    archive: [],
    puts: [],
    calls: [],
    manager: null
  }
}

export function sortOptionsTableByExpiration (
  optionA,
  optionB,
  direction = 'ascending'
) {
  try {
    const optionATimes = _.get(
      optionA,
      `cells[${_.get(optionA, 'cells.length') - 1}].durations`
    )
    const optionBTimes = _.get(
      optionB,
      `cells[${_.get(optionA, 'cells.length') - 1}].durations`
    )

    const distanceA = new BigNumber(_.get(optionATimes, 'expiration'))
    const distanceB = new BigNumber(_.get(optionBTimes, 'expiration'))

    if (distanceA.isEqualTo(distanceB)) return 0
    if (distanceA.isGreaterThan(distanceB)) {
      return direction === 'ascending' ? 1 : -1
    }
    return direction === 'ascending' ? -1 : 1
  } catch (e) {
    console.error('Filtering', e)
  }
  return 0
}

export function sortOptionsTableByState (optionA, optionB) {
  try {
    const optionATimes = _.get(
      optionA,
      `cells[${_.get(optionA, 'cells.length') - 1}].durations`
    )
    const optionBTimes = _.get(
      optionB,
      `cells[${_.get(optionA, 'cells.length') - 1}].durations`
    )

    const isExpiredA = _.get(optionATimes, 'isExpired')
    const isExpiredB = _.get(optionBTimes, 'isExpired')

    if (isExpiredA && isExpiredB) return 0
    if (isExpiredA) return 1
    return -1
  } catch (e) {
    console.error('Filtering', e)
  }
  return 0
}

export function findPageByRoute (route, pages) {
  return Object.values(pages)
    .filter(page => !_.isNil(page.depth) && page.depth === 0)
    .find(
      page =>
        matchPath(route, {
          path: page.route,
          exact: true
        }) !== null
    )
}

/**
 *
 * @param {string|number} source
 * @param {number} [rounding] BigNumber.ROUND_UP | BigNumber.ROUND_DOWN
 * @param {number} [precision]
 * @returns {string}
 */
export function toSignificantInput (
  source,
  rounding = BigNumber.ROUND_UP,
  precision = macros.COMMON_PRECISION
) {
  if (_.isNilOrEmptyString(source)) return ''

  const amount = new BigNumber(source)
  const formatted = amount.decimalPlaces(precision, rounding).toString()

  if (new BigNumber(formatted).isZero()) return ''

  return formatted
}

/**
 *
 * @param {string|number} source
 * @param {number} [rounding] BigNumber.ROUND_UP | BigNumber.ROUND_DOWN
 * @param {number} [precision]
 * @param {number} [fallback]
 * @returns {number}
 */
export function toSignificantInputNumber (
  source,
  rounding = BigNumber.ROUND_UP,
  precision = macros.COMMON_PRECISION,
  fallback = 0
) {
  const formatted = toSignificantInput(source, precision, rounding)
  if (_.isNilOrEmptyString(formatted)) return fallback

  return new BigNumber(formatted).toNumber()
}

/**
 *
 * @param {number | string} value
 * @returns
 */
export function toNumeralPrice (value, dollar = true, restrict = false) {
  if (_.isNil(value)) {
    if (restrict) return macros.RESTRICTED_PREMIUM
    return '0'
  }
  if (value === macros.RESTRICTED_PREMIUM) return value
  const instance = new BigNumber(value)

  if (instance.isZero()) {
    if (restrict) return macros.RESTRICTED_PREMIUM
    else return `${dollar ? '$' : ''}0`
  }

  if (
    instance.absoluteValue().isLessThan(macros.MINIMUM_BALANCE_AMOUNT) ||
    _.isNaN(value)
  ) {
    return `<${dollar ? '$' : ''}0`
  }

  if (instance.absoluteValue().isLessThan(new BigNumber(0.001))) {
    return numeral(value).format(`${dollar && '$'}0,0.[000000]`)
  }
  if (instance.absoluteValue().isLessThan(new BigNumber(1))) {
    return numeral(value).format(`${dollar && '$'}0,0.[0000]`)
  }
  return numeral(value).format(`${dollar && '$'}0,0.[00]`)
}

export function zeroIfNaN (v) {
  return _.isNil(v) || (BigNumber.isBigNumber(v) && v.isNaN())
    ? 0
    : v.toNumber()
}

export function getInterpretedToken (item, networkId) {
  if (_.isNil(item) || _.isNil(item, 'symbol')) return null
  const key = String(_.get(item, 'symbol')).toUpperCase()
  const network = _.get(item, 'networkId') || networkId
  const scanner = _.get(networks, `_data[${networkId}].explorer`)
  const fallback = (() => {
    const general = _.get(tokens.general, key)
    const specific = _.get(tokens[network], key)
    return { ...(general || {}), ...(specific || {}) }
  })()

  const result = _.cloneDeep(item)

  const icon = _.attempt(() => {
    const f = _.get(fallback, 'icon')
    if (!_.isNil(f) && _.isFunction(f)) return f()
    return result.icon ? result.icon : null
  })

  const alias = _.has(fallback, 'alias')
    ? _.get(fallback, 'alias')
    : _.get(result, 'symbol')

  return Object.assign(result, {
    ...fallback,
    alias,
    icon,
    key,
    scanner
  })
}

export function isBalanceInsignificant (source) {
  try {
    if (_.isNilOrEmptyString(source)) {
      return true
    }
    const balance = new BigNumber(source)

    if (
      balance.isZero() ||
      balance.isLessThanOrEqualTo(new BigNumber(macros.MINIMUM_BALANCE_AMOUNT))
    ) {
      return true
    }

    return false
  } catch (e) {
    return true
  }
}

export const toHex = num => {
  if (_.isNilOrEmptyString(num)) return '0x' + '0'.toString(16)
  return '0x' + String(num).toString(16)
}

export function formatChainForMetamask (chain) {
  return {
    chainId: toHex(chain.chainId), // A 0x-prefixed hexadecimal string
    chainName: chain.name,
    nativeCurrency: {
      name: chain.token.name,
      symbol: chain.token.symbol, // 2-6 characters long
      decimals: chain.token.decimals
    },
    rpcUrls: chain.rpc,
    blockExplorerUrls: [chain.explorer]
  }
}

export function handleTransactionReason (_code) {
  try {
    const code = parseInt(_code)
    if (code === 4001) return words.reasonTransactionRejected()
    if (code === -32016) return words.reasonTransactionExcepted()
    return words.reasonTransactionFailed()
  } catch (e) {
    return words.reasonTransactionFailed()
  }
}
