import IconDashboard from '@material-ui/icons/DashboardRounded'
import IconInvest from '@material-ui/icons/SendRounded'
import IconHedge from '@material-ui/icons/SecurityRounded'
import IconActivity from '@material-ui/icons/EventNoteRounded'
import IconPool from '@material-ui/icons/CategoryRounded'

export const pages = {
  stats: {
    route: '/stats',
    builder: () => '/stats',
    title: 'Stats',
    Icon: IconDashboard,
    depth: 0
  },
  activity: {
    route: '/',
    builder: () => '/',
    title: 'Activity',
    Icon: IconActivity,
    depth: 0
  },
  restricted: {
    route: '/restricted',
    builder: () => '/restricted',
    title: 'Terms of Service restrictions'
  },

  hedge: {
    Icon: IconHedge
  },
  invest: {
    Icon: IconInvest
  },
  pool: {
    Icon: IconPool
  }
}
