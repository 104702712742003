export const ENVIRONMENT = {
  current: String(
    process.env.REACT_APP_PODS_ENV || process.env.NODE_ENV || 'development'
  ).toLowerCase(),
  development: 'development',
  production: 'production',
  staging: 'staging',
  experimental: 'experimental',
  isDevelopment: () => ENVIRONMENT.current === ENVIRONMENT.development,
  isProduction: () => ENVIRONMENT.current === ENVIRONMENT.production,
  isStaging: () => ENVIRONMENT.current === ENVIRONMENT.staging,
  isExperimental: () => ENVIRONMENT.current === ENVIRONMENT.experimental
}
