import _ from 'lodash'
import React, { useMemo } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { macros } from '@pods-finance/globals'
import { useModal } from '@pods-finance/contexts'
import IconClose from '@material-ui/icons/CloseRounded'
import Backdrop from '../../atoms/Backdrop'

const WrapperPartial = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: ${props => props.elevation || props.theme.sizes.modalElevation};
  width: 100vw;
  height: 100vh;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 15px;
`
const ModalBackdrop = styled(Backdrop)`
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 200;
  width: 100%;
  max-width: 800px;
  background-color: ${props => props.theme.colors.white};
  border-radius: 10px;
  overflow-x: hidden;
  max-height: 96vh;

  ${props =>
    props.theme.isDark &&
    css`
      border: 1px solid ${props => props.theme.colors.border};
      box-shadow: 0 0 80px 0 rgba(0, 0, 0, 0.9);
    `}
`

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: calc(${props => props.theme.sizes.edge} * 1);
  padding-left: calc(${props => props.theme.sizes.edge} * 2);
  padding-bottom: 0;
`

const Title = styled.div`
  flex: 1;
  padding-right: ${props => props.theme.sizes.edge};

  & > p {
    font-size: 15pt;
    font-family: ${props => props.theme.fonts.primary};
    color: ${props => props.theme.colors.dark};
    font-weight: 600;
    margin: 0;
  }
`

const Close = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  width: 46px;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${props => props.theme.colors.white};

  & > svg {
    color: ${props => props.theme.colors.contentMedium};
    font-size: 16pt;
  }
  &:hover,
  &:active {
    background-color: ${props => props.theme.colors.platform};
  }
  ${props =>
    props.theme.isDark &&
    css`
      background-color: rgba(0, 0, 0, 0.3);
      & > svg {
        color: ${props => props.theme.colors.whiteAbsolute};
      }
    `}
`

const Main = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 0 calc(${props => props.theme.sizes.edge} * 2);
`

const Footer = styled.div`
  min-height: calc(${props => props.theme.sizes.edge} * 2);
`

const Wrapper = styled(WrapperPartial)`
  opacity: 1;
  transition: opacity 150ms;

  &[data-visible='false'] {
    pointer-events: none;
    opacity: 0;
    transition: opacity 150ms;
    ${Card} {
      transform: translateY(20px);
      transition: transform 300ms;
    }
  }
  &[data-visible='true'] {
    ${Card} {
      transform: translateY(0);
      transition: transform 300ms;
    }
  }

  &[data-disabled='true'] {
    pointer-events: none;
    &:after {
      content: '';
      left: 0;
      top: 0;
      position: fixed;
      width: 100vw;
      height: 100vh;
      z-index: 8000;
      background: rgba(0, 0, 0, 0.4);
    }
  }
`

function Modal ({
  className,
  id,
  title,
  children,
  footer,
  elevation,
  isDisabled,
  isCloseHidden,
  isBackdropDisabled
}) {
  const { isOpen, setOpen } = useModal(id)

  const portal = useMemo(
    () =>
      typeof window === 'undefined'
        ? null
        : document.getElementById(macros.PODS_MODAL_PORTAL),
    []
  )
  if (!portal) return null

  return ReactDOM.createPortal(
    <Wrapper
      className={className}
      data-visible={isOpen}
      elevation={elevation}
      id={id}
      data-disabled={isDisabled}
    >
      <Card data-component='card'>
        <Header>
          <Title>{_.isString(title) ? <p>{title}</p> : title}</Title>
          {!isCloseHidden && (
            <Close onClick={() => setOpen(false)}>
              <IconClose />
            </Close>
          )}
        </Header>
        <Main>{children}</Main>
        <Footer>{footer}</Footer>
      </Card>
      <ModalBackdrop
        isAnimated={false}
        isOpen={isOpen}
        onClick={() => {
          if (!isBackdropDisabled) setOpen(false)
        }}
      />
    </Wrapper>,
    portal
  )
}

Modal.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  elevation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node])
    .isRequired,
  footer: PropTypes.any,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  isDisabled: PropTypes.bool,
  isBackdropDisabled: PropTypes.bool,
  isCloseHidden: PropTypes.bool
}

Modal.defaultProps = {
  className: null,
  footer: null,
  children: null,
  elevation: null,
  isDisabled: false,
  isBackdropDisabled: false,
  isCloseHidden: false
}

export default Modal
