import _ from 'lodash'
import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useMemo
} from 'react'

export const ModalContext = createContext(null)

export default function Provider ({ children }) {
  const value = useModals()

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
}

export function useModalContext () {
  return useContext(ModalContext)
}

function useModals () {
  const [list, setList] = useState([])

  const send = useCallback(
    (action, payload) => {
      switch (action) {
        case 'open':
          setList(prev => {
            if (_.isNil(prev.find(item => item.id === _.get(payload, 'id')))) {
              return [
                ...prev,
                {
                  id: _.get(payload, 'id'),
                  isOpen: true,
                  data: _.get(payload, 'data')
                }
              ]
            }
            return prev.map(item =>
              item.id === _.get(payload, 'id')
                ? {
                  ...item,
                  isOpen: true,
                  data: _.get(payload, 'data') || item.data
                }
                : item
            )
          })
          break
        case 'close':
          setList(prev => {
            return prev.map(item =>
              item.id === _.get(payload, 'id')
                ? { ...item, isOpen: false }
                : item
            )
          })
          break
        case 'set':
          setList(prev => {
            return prev.map(item =>
              item.id === _.get(payload, 'id')
                ? { ...item, data: _.get(payload, 'data') }
                : item
            )
          })
          break
        case 'update':
          setList(prev => {
            return prev.map(item =>
              item.id === _.get(payload, 'id')
                ? {
                  ...item,
                  data: {
                    ..._.toPlainObject(item.data),
                    ...(_.get(payload, 'data') || {})
                  }
                }
                : item
            )
          })
          break
        default:
          break
      }
    },
    [setList]
  )

  return {
    list,
    send
  }
}

export function useModal (id) {
  const { list, send } = useModalContext()

  const modal = useMemo(() => list.find(item => item.id === id), [list, id])

  const { isOpen, data: modalData } = useMemo(
    () => modal || { id, isOpen: false, data: null },
    [modal, id]
  )

  const setOpen = useCallback(
    (state = true, data = null) => {
      if (state) {
        send('open', { id, data })
      } else {
        send('close', { id, data })
      }
    },
    [send, id]
  )

  const setData = useCallback(
    data => {
      send('set', { id, data })
    },
    [send, id]
  )

  const updateData = useCallback(
    data => {
      send('update', { id, data })
    },
    [send, id]
  )

  return {
    isOpen,
    setOpen,
    setData,
    updateData,
    data: modalData
  }
}
